import axios from 'axios';
import UserService from "../services/UserService";
import AuthenticateModuleAPI from '../API/AuthenticateModuleAPI';
import store from '../redux/store';
import { BMP_BE, BMP_FE } from '../configurations/ServerCreds';
import { connect } from 'react-redux';
import { setStoreData } from "../component/AuthenticationModule/fetchDataSlice";
import { toast_notify } from '../services/ToastServices';
import { Buffer } from 'buffer';
import Swal from 'sweetalert2';
import { PubtriggerNavigation } from '../services/PubSubCommunication';
import { loader } from '../services/LoaderSerivces';

const AxiosIndex = axios.create({
    timeout: 300000,
});

const mapStateToProps = state => ({
    data: state.fetchDataSlice.data
});
const mapDispatchToProps = dispatch => ({
    setStoreData: data => dispatch(setStoreData(data))
});

axios.interceptors.request.use(
    config => {
        const storeData = store.getState()
        if (storeData.fetchDataSlice.data && storeData.fetchDataSlice.data.tokenData && config.url != `${BMP_BE}oauth/token`) {
            config.headers['Authorization'] = 'Bearer ' + storeData.fetchDataSlice.data.tokenData.access_token;
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
);
let refresh = false;
let failed = [];
axios.interceptors.response.use(
    response => {
        return response
    },
    async (error) => {
        const originalRequest = error.config;
        const storeData = store.getState();
        try {
            if (error.status && error.status == 400) {
                if (error.message === 'Network Error') {
                    toast_notify("Network error", "error")
                    // return Promise.reject(error);
                }
            }
            if (error.response && error.response.status === 400 && error.response.data.errorMessage === "Invalid File Content") {
                Swal.fire({
                    title: "Error",
                    footer: "",
                    type: "error",
                    icon: "error",
                    text: "Invalid File content",
                });
            }
            if (error.response && error.response.status === 400 && error.response?.data?.errorMessage === "We detected malicious content in the file. Please check and try again.") {
                loader("stop");
                Swal.fire({
                    title: "Not Allowed!",
                    footer: "",
                    type: "error",
                    icon: "error",
                    text: error.response.data.errorDescription,
                });
            }
            if (error.response && error.response.status === 401 && error.response.data && error.response.data.error_description && error.response.data.error_description.match("Invalid access token")
                && error.config.url != `${BMP_BE}loginHelper/v1/logoutAction`) {
                if (!originalRequest._retry && storeData.fetchDataSlice.data && storeData.fetchDataSlice.data.tokenData) {
                    if (!refresh) {
                        refresh = true;
                        originalRequest._retry = true
                        const refreshToken = storeData.fetchDataSlice.data.tokenData.refresh_token;
                        const client_id = process.env.REACT_APP_CLIENT_ID;
                        const client_secret = process.env.REACT_APP_CLIENT_SECRET;
                        const code = `${client_id}:${client_secret}`;
                        const encodedCode = Buffer.from(code).toString('base64');
                        const basicAuth = `Basic ${encodedCode}`;
                        let data = {
                            grant_type: 'refresh_token',
                            refresh_token: refreshToken
                        }
                        let config = {
                            headers: {
                                'Authorization': basicAuth,
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                        await AuthenticateModuleAPI.authenticateUser(data, config)
                            .then(async res => {
                                if (res.status === 200) {
                                    store.dispatch(setStoreData({ tokenData: res.data }));
                                    refresh = false;
                                    failed.forEach((request) => request())
                                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                                    failed = [];
                                    return axios(originalRequest)
                                }
                            })
                            .catch(error => {
                                sessionStorage.clear();
                                localStorage.clear();
                                PubtriggerNavigation("/login");
                                toast_notify("Your session is expired", "error");
                            })
                    }
                    else {
                        const request = new Promise((resolve, reject) => {
                            failed.push(() => {
                                resolve(axios(error.config))
                            })
                        });
                        return request;
                    }
                }
                else {
                    if (error.config.url === `${BMP_BE}oauth/token` && error.response && error.response.data.error_description.match('Invalid refresh token')) {
                        UserService.handleLogout();
                    }

                }
            }
            else if (error.response && error.response.status === 401 && error.config.url === `${BMP_BE}loginHelper/v1/logoutAction`) {
                console.log(error)
            }
            else if (error.response && error.response.status >= 500 && error.response.status <= 599) {
                if (error.response.data && error.response.data.errorDescription === "Access is denied") {
                    PubtriggerNavigation("/botlist");
                    toast_notify("You are not authorized to view this page", "error");
                }
                else {
                    toast_notify("An internal server error has occured. Please try again", "error");
                }
            }
            else {
                // toast_notify("Something went wrong. Please try again","error")
                console.log(error)
            }
            return Promise.reject(error)
        }
        catch (err) {
            console.log(error)
            return Promise.reject(error);
            // return error;
        }
    }
);


export default connect(mapStateToProps, mapDispatchToProps)(AxiosIndex);