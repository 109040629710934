import React, { useEffect, useState } from "react";
import { toast_notify } from "../../../services/ToastServices";
import "./ContextualTrainingPopup.css";
import { BMP_BE } from "../../../configurations/ServerCreds";
import { loader } from "../../../services/LoaderSerivces";
import axios from "axios";
const ContextualTrainingPopup = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const uploadedFile = Array.from(e.target.files);
    const totalFiles = selectedFiles.length + uploadedFile.length;
    if (totalFiles < 6) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...uploadedFile]);
    } else {
      toast_notify("Maximum of 5 files are allowed", "error");
    }
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const handleTrain = () => {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("botId", sessionStorage.getItem("botId"));
    if (selectedFiles.length) {
      loader("start");
      return axios
        .post(`${BMP_BE}openAi/v1/train`, formData, config)
        .then((res) => {
          setSelectedFiles([]);
          props.getTrainedFiles(0, 10);
          loader("stop");
          props.setShowAddFileModal(false);
          toast_notify("File trained successfully", "success");
        })
        .catch((err) => {
          console.log(err);
          loader("stop");
          toast_notify(err?.response?.data?.errorDescription, "error");
        });
    } else {
      toast_notify("Please select files to train", "error");
    }
  };

  return (
    <section id="popout-full">
      <div className="View-page view-page-popup" id="slideopen">
        <form>
          <div className="sub-title sub-title-popup contextualformpopup">
            <div className="left-title-section">
              <h4 className="m-0 pull-left">Upload Training Files</h4>
            </div>
            <div className="right-title-section">
              <i
                className="ti-close"
                onClick={() => props.setShowAddFileModal(false)}
                title="Close Form"
              />
            </div>
          </div>
          <div className="view-popup-body" id="view-page-scroll">
            <div className="form-group">
              <label className="mid-size" htmlFor="exampleInputFile">
                Select Files:
              </label>

              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="exampleInputFile"
                    aria-describedby="fileHelp"
                    accept=".doc, .DOC, .docx, .DOCX, .csv, .CSV, .xls, .XLS, .xlsx, .XLSX, .pdf, .PDF, .ppt, .PPT, .pptx, .PPTX, .txt, .TXT, .jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                    onChange={handleFileChange}
                    multiple
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="exampleInputFile"
                  >
                    Maximum of 5 files are allowed
                  </label>
                </div>
              </div>
              <p>
                * File formats allowed - .doc, .docx, .csv, .xls, .xlsx, .pdf,
                .ppt, .pptx, .txt, .jpg, .jpeg, .png
              </p>
              <p>* Maximum size allowed for each file is 5mb.</p>
              {
                <div className="file-upload-list">
                  <p className="selected-files mid-size">
                    {selectedFiles.length > 0
                      ? "Selected Files:"
                      : "No files selected"}
                  </p>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      <div className="file-info file-show">
                        <span className="file-name">{file.name}</span>
                        <div
                          className="remove-files"
                          onClick={() => handleRemoveFile(file)}
                        >
                          <img src="/bmpadmin/assets/images/close-icon.png" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
          <div className="View-page-footer view-popup-footer">
            <div
              className="btn btn-primary btn-sm cont-btn"
              title="Train"
              onClick={(e) => handleTrain(e)}
            >
              Train
            </div>
            <div
              className="btn btn-secondary btn-sm cont-btn"
              onClick={() => props.setShowAddFileModal(false)}
              title="Close Form"
            >
              Close
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContextualTrainingPopup;
