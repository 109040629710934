import axios from "axios";
import { Redirect } from "react-router";

import {
    CURRENT_SERVER,
    // logout_server,
    BMP_FE,
    BASE_URL
} from "../../src/configurations/ServerCreds"
import AuthenticateModuleAPI from "../API/AuthenticateModuleAPI";
import store from "../redux/store";
import { loader } from "./LoaderSerivces";
import { PubtriggerNavigation } from "./PubSubCommunication";

class UserService {

    getUserName() {
        const firstName = sessionStorage.getItem("firstName");
        const lastName = sessionStorage.getItem("lastName");
        const storeData = store.getState();
        if (firstName == null && lastName == null) {
            if (storeData.fetchDataSlice && storeData.fetchDataSlice.data && storeData.fetchDataSlice.data.authData) {
                return `${storeData.fetchDataSlice.data.authData.firstName} ${storeData.fetchDataSlice.data.authData.lastName}`
            }
            else {
                return sessionStorage.getItem("userName");
            }
        }
        else {
            if (lastName == null) {
                return firstName
            }
            else {
                return `${firstName} ${lastName}`
            }
        }
        // let username = JSON.parse(userObject).userName;
    }

    getEmail() {
        let userObject = sessionStorage.getItem("emailid");
        // let email = JSON.parse(userObject).email;
        return userObject;
    }

    // getUserData() {
    //     let userObject = sessionStorage.getItem("loggedInUser");
    //     let userData = JSON.parse(userObject);
    //     return userData;
    // }

    handleLogout() {
        const storeData = store.getState();
        if (storeData.fetchDataSlice && storeData.fetchDataSlice.data.tokenData) {
            let data = {
                "accessToken": storeData.fetchDataSlice.data.tokenData.access_token,
                "sessionId": storeData.fetchDataSlice.data.sessionId
            }
            let config = {
                headers: { 'Authorization': 'Bearer ' + storeData.fetchDataSlice.data.tokenData.access_token }
            }
            // const endpoint = CURRENT_SERVER + "logouttime";
            AuthenticateModuleAPI.logoutAction(data, config)
                .then((res) => {
                    sessionStorage.removeItem("Loggined");
                    PubtriggerNavigation("/login");
                    const keysToKeep = ['captchaCount', 'loggined_livechat','persist:rootLA','userNameLive'];
                    const allKeys = Object.keys(localStorage);
                    allKeys.forEach(key => {
                    if (!keysToKeep.includes(key)) {
                        localStorage.removeItem(key);
                    }
                    });
                    sessionStorage.clear();
                    loader('stop');

                })

                .catch((error) => {
                    PubtriggerNavigation("/login");
                    sessionStorage.clear();
                    localStorage.clear();
                });
        }
        else {
            PubtriggerNavigation("/login");
            sessionStorage.clear();
            localStorage.clear();
        }
    }

}

export default new UserService();
